import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { HashRouter } from "react-router-dom"
import App from "./App"
import { store } from "./app/store"
import "./index.css"
import { YandexMapProvider } from "./common/yandex/provider/YandexMapProvider"
import {GoogleOAuthProvider} from "@react-oauth/google"
const clientId = "381983393501-5j0q3ggtesutvpobaqa6g7uj1gfve1i2.apps.googleusercontent.com"

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <HashRouter>
      <YandexMapProvider>
        <GoogleOAuthProvider clientId={clientId}>
        <App />
        </GoogleOAuthProvider>
      </YandexMapProvider>
    </HashRouter>
  </Provider>,
)
