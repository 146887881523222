import { Box, Pagination, Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FilterIcon from "../../assets/svg/filter-pack/filter.svg?react";
import CategoryTabs from "../../components/CategoryTabs";
import { feedPaginCont } from "../../components/feed_page/feedPageStyles";
import Filtered from "../../components/filtered";
import GradientBtn from "../../components/GradientBtn";
import { CATEGORY_SHOULD_DISPLAYED } from "../../consts";
import useResize from "../../hooks/useResize";
import {
  btnCreateEventCont,
  btnsTabsCont,
  feedCont,
  fidContent,
  fidWrapper,
  filterForMapPage,
  filterIconCont,
  newEvent,
  tabsContainer
} from "./fidStyles";
import { useSizeOfPagination } from "./hooks/useSizeOfPagination";
import s from "./styles.module.css";
import { useIsAuthorizedUser } from "../../hooks/useIsAuthorizedUser";
import { show } from "../signIn/SignInSlice";
import { show as showEvent } from "../makeEvent/slice/MakeEventSlice";
import useFeedPaginationState from "../../shared/events-feed/hooks/useFeedPagination";
import { selectTotalElements } from "../../shared/feed-pagination/selectors";
import EventProvider from "../../shared/events-feed/event-provider";

const isActiveButton = (v: { isActive: boolean }) =>
  v.isActive ? `${s.button_feed} ${s.active}` : s.button_feed

const Fid = () => {
  const dispatch = useAppDispatch();
  const isAuth = useIsAuthorizedUser()
  const totalPages: number  = useAppSelector(selectTotalElements)
  const { pathname } = useLocation()
  const screenWidth = useResize()
  const [isFilterShow, setIsFilterShow] = useState(false)

  const {page, setPage, size} = useFeedPaginationState()

  const handleChangePage = (_: any, num: number) => {

    if (num === +page) return
    setPage(num)
  }
  const onCreateEvent = () => {
    if (!isAuth) {
      dispatch(show());
    } else {
      dispatch(showEvent());
    }
  }
  const totalQuantityOfPages = useMemo(() => {
    return totalPages && Math.floor(totalPages / size)
  }, [screenWidth, page, totalPages])

  const sizeOfPagination = useSizeOfPagination()
  return (
    <div style={fidWrapper}>
      <div style={fidContent}>
        {CATEGORY_SHOULD_DISPLAYED.includes(pathname) && <CategoryTabs />}

        <Box sx={feedCont}>
          <Box sx={btnsTabsCont}>
            <Box sx={tabsContainer}>
              <NavLink to={"/"} className={isActiveButton}>
                Лента
              </NavLink>

              <NavLink to={"/map"} className={isActiveButton}>
                На карте
              </NavLink>
            </Box>
          </Box>

          <Box sx={btnCreateEventCont}>
            {pathname === "/map" &&
              screenWidth >= 768 &&
              screenWidth <= 1033 && (
                <>
                  <Box
                    onClick={() => setIsFilterShow((prev) => !prev)}
                    sx={{
                      ...filterIconCont,
                      background: isFilterShow
                        ? "var(--btns-selected)"
                        : "transparent",
                    }}
                  >
                    <FilterIcon
                      stroke={`var(--secondary-text)`}
                      style={{ position: "absolute" }}
                    />
                  </Box>

                  <Filtered
                    isOpenFilter={isFilterShow}
                    setIsOpenFilter={setIsFilterShow}
                    extraStyles={{
                      ...filterForMapPage,
                      right: isFilterShow ? 25 : -400,
                    }}
                  />
                </>
              )}

            {screenWidth >= 1033 && pathname === "/map" && (
              <GradientBtn handleAction={onCreateEvent} title="+ Создать событие" btnStyle={newEvent} />
            )}

            {screenWidth >= 768 && pathname !== "/map" && (
              <GradientBtn handleAction={onCreateEvent} title="+ Создать событие" btnStyle={newEvent}  />
            )}
          </Box>
        </Box>
        <EventProvider>
          <Box sx={{ ...feedCont, paddingTop: "0 !important" }}>
            {pathname === "/map" && screenWidth >= 1033 && <Filtered />}
            {pathname !== "/map" && screenWidth >= 768 && <Filtered />}
            <Outlet />
          </Box>
        </EventProvider>


        {pathname === "/" && totalPages && totalQuantityOfPages && (
          <Stack sx={feedPaginCont}>
            <Pagination
              count={totalQuantityOfPages}
              page={page}
              variant="outlined"
              shape="rounded"
              size={sizeOfPagination}
              boundaryCount={1}
              siblingCount={3}
              onChange={handleChangePage}
            />
          </Stack>
        )}
      </div>
    </div>
  )
}

export default Fid
