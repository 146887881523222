import { FC, useState } from "react";
import { EventDto } from "../../../shared/events-feed/slice/types";
import { CardActions } from "@mui/material";
import { Link } from "react-router-dom";
import FallbackImg from "../../../assets/svg/profile/logo-icon.svg";
import ShareButton from "../components/ShareButton";
import CalendarIconCard from "/src/assets/svg/card-icons-pack/calendar-icon.svg?react";
import ClockIconCard from "/src/assets/svg/card-icons-pack/clock-icon.svg?react";
import LocationIconCard from "/src/assets/svg/card-icons-pack/location-icon.svg?react";
import InfoLabel from "../components/InfoLabel";
import CardTitle from "../components/CardTitle";
import { Row } from "../../index";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

import { FeedCardContent, StyledCard, StyledCardMedia } from "../styles/Card";
import ParticipantsAvatarRow from "../components/ParticipantsAvatarRow";
import CardDescription from "../components/CardDescription";
import CardBadge from "../components/CardBadge";
import CardDivider from "../components/CardDivider";
import formatDateTime from "../components/formatDateTime";
import addressFormat from "../components/addressFormat";
import SharedEventDialog from "../../Dialogs/SharedEventDialog";
import WalletIconStyled from "../components/WalletIconStyled";


export type FeedCardProps = {
  eventInfo: EventDto
}
const FeedCard: FC<FeedCardProps> = ({ eventInfo }) => {
  const formatTime = formatDateTime(eventInfo.startTime);
  const [isVisibleDialog, setIsVisibleDialog] = useState(false);
  const openSharedDialog = async () => {
    setIsVisibleDialog(true)
  }
  const closeSharedDialog = () => {
    setIsVisibleDialog(false)
  }

  return (<StyledCard>
    <Link to={`fid/${eventInfo.eventId}`}>
      <StyledCardMedia
        image={eventInfo.avatarUrl[0] ?? FallbackImg}
        title={eventInfo.eventName}
      >
        <CardBadge time={eventInfo?.startTime} />
        {!eventInfo.isFree && <WalletIconStyled />}
      </StyledCardMedia>
    </Link>
    <FeedCardContent>
      <Row>
        <Link to={`fid/${eventInfo.eventId}`}>
        <CardTitle title={eventInfo.eventName} />
        </Link>
        <ShareButton onClick={openSharedDialog}/>
      </Row>
      <InfoLabel
        text={addressFormat(eventInfo.eventAddress)}
        LeftIcon={LocationIconCard}
      />
      <Row justifyContent="start" gap={14}>
        <InfoLabel text={formatTime?.date} LeftIcon={CalendarIconCard} />
        <InfoLabel text={formatTime?.time} LeftIcon={ClockIconCard} />
      </Row>
      <CardDivider />
      <Link to={`fid/${eventInfo.eventId}`}>
        <CardDescription text={eventInfo.description} />
      </Link>
    </FeedCardContent>
    <CardActions>
      <ParticipantsAvatarRow participants={eventInfo.participants} maxCount={5} />
    </CardActions>
    <SharedEventDialog info={eventInfo} open={isVisibleDialog} onClose={closeSharedDialog}/>
  </StyledCard>);
};
export default FeedCard;